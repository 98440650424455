// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/css/OverlayScrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';
//Boostrp4-toggle
@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';
//Select2
@import '~select2/dist/css/select2.min.css';
//Select2 Bootstrap
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';
// DataTables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import '~toastr/toastr';
// Swall
@import '~sweetalert2/src/sweetalert2.scss';
@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
//@import '~frappe-charts/dist/frappe-charts.min.css';
@import '~frappe-charts/src/css/charts.scss';

@import 'custom';

// @import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';
// Bootstrap
// Already imported by AdminLTE
//@import '~bootstrap/scss/bootstrap';
